import React from 'react'

const TreatmentCard = ({title, whyToUse, imagaUrl, content}) => {
  return (
    <div className='flex flex-col relative rounded-xl md:flex-row md:ml-12 md:mr-12 mt-4'>
        <img src={imagaUrl} alt='Dr.Nirose profile' className='h-56 w-full rounded-xl md:h-auto md:w-96'></img>
        <div className='-mt-6  bg-blue-50 border border-blue-100 rounded-3xl md:mt-0 md:w-96 md:-ml-6'>
        <div className='flex w-full justify-between'>
        <h1 className=' text-white font-bold self-start m-2 bg-green-700 rounded-3xl px-4 py-1'>{title}</h1>
        <a href='tel:9445581844'>
        <h1 className=' text-white font-bold self-center m-2 bg-blue-700 rounded-3xl px-4 py-1'> Book Now</h1>
          </a> 
        </div>      
        <h2 className='font-semibold self-start p-2 text-xl font-header'>{whyToUse}</h2>
        <p className='pl-2 pr-2 text-sm text-gray-600 text-justify pb-4 font-header' >{content}</p>
        </div>       
    </div>
  )
}

export default TreatmentCard;