import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Home from './kottakkalaryavaidyasalapammal-hero';
import Treatment from './kottakkalaryavaidyasalapammal-treatments';
import AboutUs from './kottakkalaryavaidyasalapammal-aboutus';
import Contact from './kottakkalaryavaidyasalapammal-contact';
import { useState } from 'react';

function App() {

  const[homeMenu, setHomeMenu] = useState(true)
  const[aboutMenu, setAboutMenu] = useState(false)
  const[treatmentMenu, setTreatmentMenu] = useState(false)
  const[contactMenu, setContactMenu] = useState(false)

  const MenuIndicator = (name) =>{
    console.log( name + ' Menu clicked')
    if(name === 'home'){
      setHomeMenu(true)
      setAboutMenu(false)
      setTreatmentMenu(false)
      setContactMenu(false)
    }
    if(name === 'aboutus'){
      setAboutMenu(true)
      setTreatmentMenu(false)
      setContactMenu(false)
      setHomeMenu(false)
    }
    if(name === 'treatment'){
      setTreatmentMenu(true)
      setContactMenu(false)
      setHomeMenu(false)
      setAboutMenu(false)
    }
    if(name === 'contactus'){
      setContactMenu(true)
      setHomeMenu(false)
      setAboutMenu(false)
      setTreatmentMenu(false)
    }
  }
  
  return (
    <Router>
      <div className="App bg-gradient-to-r from-green-100 via-blue-100 to-blue-100">
      <header className='flex flex-wrap justify-between items-center fixed top-0 left-0 right-0 bg-gradient-to-r from-blue-200 via-green-200 to-blue-200 z-50 md:justify-start md:flex-nowrap'>
          <img src='./assets/kottakalaryavaidyasalapammal-logo.svg' alt='kottakkal arya vaidya sala pammal logo' className=' h-24  w-40 -mt-4'/>
         <h2 className='self-center pr-2 flex md:order-last md:-mt-2 font-header font-semibold text-md md:text-xl'> <span><a href='tel:9445581844'><img src='./assets/phone.svg' className=' h-6 pr-2 md:h-7' alt='kottakkal arya vaidya sala pammal phone icon'></img></a></span> 94455 81844</h2>
          <ul className='flex justify-between items-center w-full -mt-2 pl-2 pr-2 pb-2 border-b border-none md:justify-center md:space-x-16 md:w-3/4'>
            <li className={homeMenu ?  'border-t-4 rounded-sm border-green-700 text-green-700 font-semibold' :''}><Link className=' font-header md:text-md font-semibold' to="/" onClick={() => MenuIndicator('home') }>Home</Link></li>         
            <li className={treatmentMenu ?  'border-t-4 rounded-sm border-green-700 text-green-700 font-semibold' :''}><Link to ="/treatments-bestpachakarmatreatmentsinchennai" className=' font-header md:text-md font-semibold' onClick={() => MenuIndicator('treatment')}>Treatments</Link></li>
            <li className={aboutMenu ?  'border-t-4 rounded-sm border-green-700 text-green-700 font-semibold' :''}><Link to = "/aboutus-bestayurvedadoctorsinchennai" className=' font-header md:text-md font-semibold' onClick={() => MenuIndicator('aboutus')} >About Us</Link></li>
            <li className={contactMenu ?  'border-t-4 rounded-sm border-green-700 text-green-700 font-semibold' :''}><Link to ="/contactus-betstaryavaidyasaladealerinchennai" className=' font-header md:text-md font-semibold' onClick={() => MenuIndicator('contactus')}>Contact Us</Link></li>
          </ul>
      </header>  
      <Routes>
      <Route path="/" Component={Home}/>
      {/* <Route path="/contactus" Component={Contact}/>
      <Route path="/treatment" Component={Treatment}/>
      <Route path="/aboutus" Component={AboutUs}/> */}
      <Route path="/" Component={Home}/>
      <Route path="/contactus-betstaryavaidyasaladealerinchennai" Component={Contact}/>
      <Route path="/treatments-bestpachakarmatreatmentsinchennai" Component={Treatment}/>
      <Route path="/aboutus-bestayurvedadoctorsinchennai" Component={AboutUs}/>
      </Routes>  
      {/* <footer className='bg-gradient-to-r from-green-200 via-blue-200 to-blue-200 flex items-center justify-center'>
        <img src='./assets/about/kottakkalaryavaidyasalapammal-about-linkedin.svg' className='h-6 w-6'></img>
        <h2 className='text-center p-2 font-Outfit md:p-4'> A Palanivel Mani Design</h2>
      </footer> */}

    </div>
    </Router>
    
  );
}

export default App;
