import React from "react";

const Review = () =>{
    return(
        // <div className="flex">
        //   <img src="./assets/kottakkalaryavaidyasalapammal-services-star.svg" className="h-5 w-5"></img>
        //   <img src="./assets/kottakkalaryavaidyasalapammal-services-star.svg" className="h-5 w-5"></img>
        //   <img src="./assets/kottakkalaryavaidyasalapammal-services-star.svg" className="h-5 w-5"></img>
        //   <img src="./assets/kottakkalaryavaidyasalapammal-services-star.svg" className="h-5 w-5"></img>
        //   <img src="./assets/kottakkalaryavaidyasalapammal-services-star.svg" className="h-5 w-5"></img>
        // </div>
         <div className="flex">
         <img src="./assets/kottakkalaryavaidyasalapammal-star.png" className="md:h-5 md:w-5 h-3 w-3"></img>
         <img src="./assets/kottakkalaryavaidyasalapammal-star.png" className="md:h-5 md:w-5 h-3 w-3"></img>
         <img src="./assets/kottakkalaryavaidyasalapammal-star.png" className="md:h-5 md:w-5 h-3 w-3"></img>
         <img src="./assets/kottakkalaryavaidyasalapammal-star.png" className="md:h-5 md:w-5 h-3 w-3"></img>
         <img src="./assets/kottakkalaryavaidyasalapammal-star.png" className="md:h-5 md:w-5 h-3 w-3"></img>
       </div>
    )
}

export default Review;