import React from "react";
import './index.css';
import ServiceCard from "./kottakkalaryavaidyasalapammal-card-service";
import Card from "./kottakkalaryavaidyasalapammal-card";
import DoctorCard from "./kottakkalaryavaidyasalapammal-doctor-card";
import Review from "./kottakkalaryavaidyasalapammal-review";
import { Helmet } from "react-helmet";

const Home =()=>{
const bloodSugarContent = 'Manage your blood sugar effectively with personalized solutions at Kottakkal Arya Vaidya Sala Pammal. Embrace holistic approaches for balanced and sustainable well-being';    
const hairFallContent = 'Combat hair fall and restore luscious locks with our specialized treatments at Kottakkal Arya Vaidya Sala Pammal. Experience natural solutions tailored for strong, healthy, and beautiful hair';
const skinCareContent = 'Nurture your skin with the care it deserves at Kottakkal Arya Vaidya Sala Pammal. Our holistic skincare solutions combine traditional wisdom and modern expertise for a radiant, healthy glow.';
const boneIssueContent = 'Prioritize bone health for the elderly with our tailored solutions at Kottakkal Arya Vaidya Sala Pammal. Our comprehensive approach focuses on strengthening bones and promoting mobility.';
const infertilityContent = 'Embark on parenthood\'s journey with hope and personalized care at Kottakkal Arya Vaidya Sala Pammal. Our infertility treatments merge ancient Ayurvedic wisdom with modern expertise for holistic conception solutions';
const pilesContent = 'Find relief from piles discomfort with effective treatments at Kottakkal Arya Vaidya Sala Pammal. Our personalized approach merges Ayurvedic tradition with contemporary expertise for lasting well-being.';
const bloodPressure = 'Manage your blood pressure naturally with our holistic approach at Kottakkal Arya Vaidya Sala Pammal. Explore personalized solutions rooted in Ayurvedic wisdom for sustained cardiovascular well-being.';
const psoriasisContent = 'Discover the path to lasting comfort and confidence as our experienced practitioners at Kottakkal Arya Vaidya Sala Pammal work with you, tailoring a holistic plan to address the root causes and provide sustained relief from psoriasis';

const allProffesional = 'At Kottakkal Arya Vaidya Sala Pammal, professionalism is our hallmark. Our team, each an expert in their role, is dedicated to delivering exceptional care, fostering an environment where your health and comfort take precedence.';
const medicines = 'Your Health, Our Priority - Offering Ayurvedic Medicines from Kottakkal Arya Vaidya Sala, Every Day of the Week. Elevate Your Well-being with Quality Care, Seven Days a Week.';
const treatments ='Begin your holistic healing journey at Kottakkal Arya Vaidya Sala, your destination for comprehensive Ayurvedic care. Experience transformative Panchakarma and a spectrum of tailored traditional treatments.';
const customerSupport='Effortless Assistance: Trust the Expertise of Our Staff at Kottakkal Arya Vaidya Sala. From Consultations to Product Inquiries, We Provide Comprehensive Support for a Smooth Experience.';

const review1 = 'Medicine and consultation available you will get Ayurvedic Products as well as related medicines.'
const review2 = 'Good Service';
const review3 = 'Super';
const review4 = 'Helpful';

const introNivi = 'Experienced Ayurveda Consultant: With over 5 years of expertise, our seasoned consultant specializes in personalized Ayurvedic consultations.'
const aboutHeroService = 'We used to sell only the best quality kottakkal medicines on the market in order to provide best products to our patient'
const introPriya = 'Holistic Health Navigator: With a rich background spanning over 15 years, our Ayurveda expert specializes in crafting personalized wellness plans.'
    return(

<div className="mt-[6.4rem] md:mt-20 md:h-screen h-64 w-fit bg-cover sm:h-1/2" style={{ backgroundImage: 'url(./assets/kottakkalaryavaidyasalapammal-avs-bag.webp)'}}> 
<Helmet>
      <title>Arya Vaidya Sala Kottakkal Pammal</title>
                <meta property="og:title" content="Home - Kottakkal Arya Vaidya Sala Pammal"></meta>
                <meta property="og:image" content="/kottakkalaryavaidyasalapammal-logo.ico"></meta>
      </Helmet>
 <div className="mt-2  md:mt-6 md:ml-12 md:w-1/2 w-3/4 md:h-screen">
    <div className="flex flex-col">
    <h2 className="md:text-xl md:mt-20 md:pt-4 pt-2 text-green-800 font-bold pl-1 text-sm mr-4 ml-4">Vaidyaratnam P.S. Variers's </h2>    
    <h1 className="font-bold text-2xl md:text-8xl ml-4 sm:text-4xl">Arya Vaidya Sala Kottakkal Chennai </h1>
    <h2 className="font-header md:text-2xl md:mr-8 pt-2 text-gray-500 md:w-4/5 text-xs mr-4 ml-4">Best dealer of Arya Vaidya Sala Kottakkal in Pammal, with over 8 years of excellence in healthcare.</h2>
    <div className="pt-2 flex">
        <a href="tel:9445581844">
        <h1 className="mt-2 px-2 py-2 ml-2 text-white font-bold bg-blue-700 w-fit rounded-full md:mt-2 md:px-4 md:py-3 text-xs md:text-base">Book Appointment</h1>
        </a>
        <a href="tel:9176111064" className="md:ml-12">
        <h1 className="mt-2 px-2 py-2 ml-2 text-white font-bold bg-green-700 w-fit rounded-full md:mt-2 md:px-4 md:py-3 text-xs md:text-base">Consult Doctor</h1>
        </a>
    </div>
    <div className="flex py-4 ml-2 md:ml-2">
        <Review/>
        <p className="ml-2 -mt-1 font-bold md:font-semibold text-sm md:text-base">(4.5)</p>
    </div>
    <div className="md:-mt-4 -mt-4 ml-2 md:ml-2">
        <p className="font-semibold text-gray-500 text-sm md:text-base">50+ ratings on online platform</p>
    </div>
    {/* <div className="md:pt-2 md:-ml-4 md:w-3/4 ml-2 ">
        <DoctorCard name={''} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-nivethithakumar.jpg'} position={''} content={aboutHeroService} bgColor={'bg-white'} bgStyle={'pt-2 pb-2'}/>
    </div> */}
    </div>
 {/* <img src="./assets/kottakkalaryavaidyasalapammal-avs-bg.png" loading="lazy" className=" rounded-2xl w-48 md:w-full md:h-[40rem] bg-right bg-cover"></img> */}
</div> 
{/* <div className="md:pt-2 md:ml-5 md:w-2/4 ml-2 w-full ">
        <DoctorCard name={''} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-nivethithakumar.jpg'} position={''} content={aboutHeroService} bgColor={'bg-white'} bgStyle={'pt-2 pb-2'}/>
    </div> */}


{/* SERVICES */}
 <div className="flex flex-col items-center content-center m-8 md:m-4">
  <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
  </img>
 </div>
 <h2 className="text-center text-blue-700 font-bold text-sm mt-4 font-RobottoPara"> SERVICES</h2>
 <h1 className="text-center font-bold font-Outfit text-xl md:text-3xl">Provides Our Best Services</h1>
 <div className="flex flex-col items-center 
 md:flex-row md:space-x-8 md:justify-center">
 <ServiceCard name={'Arthritis'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-joints.png'} content={boneIssueContent} bgColor={'bg-green-50'}/>
 <ServiceCard name={'Skin care'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-skin.png'} content={skinCareContent} bgColor='bg-green-50'/>
 <ServiceCard name={'Infertility'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-services-infertility.svg'} content={infertilityContent} bgColor='bg-green-50'/>
 <ServiceCard name={'Hair care'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-services-dermatology.svg'} content={hairFallContent} bgColor='bg-green-50'/>
 </div>
<div className="flex flex-col items-center 
 md:flex-row md:space-x-8 md:justify-center">
<ServiceCard name={'Blood Sugar'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-sugar.png'} content={bloodSugarContent} bgColor='bg-green-50'/>
 <ServiceCard name={'Psoriasis'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-psoriasis.png'} content={psoriasisContent} bgColor='bg-green-50'/>
 <ServiceCard name={'Blodd Pressure'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-pressure.png'} content={bloodPressure} bgColor='bg-green-50'/>
 <ServiceCard name={'Piles'} imagaUrl={'./assets/services/kottakkalaryavaidyasalapammal-piles.png'} content={pilesContent} bgColor='bg-green-50'/>
</div>
 

{/* WHY CHOOSE US*/}
<div className="flex flex-col items-center content-center m-4">
  <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
  </img>
 </div>
<div className="flex flex-col items-center md:flex mb-4">
    <h2 className="text-center text-blue-700 font-bold text-sm font-RobottoPara"> WHY CHOOSE US</h2>
    <h1 className="text-center font-bold font-Outfit text-md mb-2 text-xl md:text-3xl">Why you Should Choose Us?</h1>
    <h1 className="text-center font-bold font-Outfit text-md mb-2 text-xl md:text-3xl">Get Know About Us</h1>
    <div className="flex flex-col items-center 
 md:flex-row md:space-x-8 md:justify-center">
 <ServiceCard name={'All Professionals'} imagaUrl={'./assets/chooseUs/kottakkalaryavaidyasalapammal-pro.png'} content={allProffesional} bgColor={'bg-blue-50'} notRounded={true}/>
 <ServiceCard name={'Medicines'} imagaUrl={'./assets/chooseUs/kottakkalaryavaidyasalapammal-medicine.png'} content={medicines} bgColor='bg-blue-50' notRounded={true}/>
 <ServiceCard name={'Treatments (Ayurchakra)'} imagaUrl={'./assets/chooseUs/kottakkalaryavaidyasalapammal-treatment.png'} content={treatments} bgColor='bg-blue-50' notRounded={true}/>
 <ServiceCard name={'Customer Support'} imagaUrl={'./assets/chooseUs/kottakkalaryavaidyasalapammal-customerSupport.png'} content={customerSupport} bgColor='bg-blue-50' notRounded={true}/>
 </div>
</div>

<div className="flex items-center justify-center space-x-2 bg-sky-500 mt-6
md:space-x-32 md:ml-6 md:mr-6">
    <div className="flex flex-col ml-4">
        <p className="font-bold text-2xl text-white pt-2 md:text-4xl">300+</p>
        <p className="font-bold text-sm text-white pb-2 md:text-xl">Happy Customers</p>
    </div>
    <div className="flex flex-col">
        <p className="font-bold text-2xl text-white pt-2 md:text-4xl">07+</p>
        <p className="font-bold text-sm text-white pb-2 md:text-xl">Years of Experience</p>
    </div>
    <div className="flex flex-col">
        <p className="font-bold text-2xl text-white pt-2 md:text-4xl">04+</p>
        <p className="font-bold text-sm text-white pb-2 md:text-xl">Experienced Doctors</p>
    </div>
    <div className="flex flex-col">
        <p className="font-bold text-2xl text-white pt-2 md:text-4xl">03+</p>
        <p className="font-bold text-sm text-white pb-2 md:text-xl">Experienced Therapists</p>
    </div>
</div>
<div className="flex flex-col items-center content-center m-4">
  <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
  </img>
 </div>
<div className="flex flex-col items-center md:flex mb-4">
    <h2 className="text-center text-blue-700 font-bold text-sm font-RobottoPara"> DOCTORS</h2>
    <h1 className="text-center font-bold font-Outfit text-md mb-2 text-xl md:text-3xl">Meet your Practioners</h1>
    <div className="flex flex-col items-center space-y-4
 md:flex-row md:space-x-8 md:justify-center md:gap-8">
    <DoctorCard name={'Dr. Priya Balaji'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-priyabalaji.webp'} position={'Consulting Doctor'} content={introPriya} bgColor={'bg-green-50'}/>
    <DoctorCard name={'Dr. Nivethitha Kumar'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-nivethithakumar.webp'} position={'Consulting Doctor'} content={introNivi} bgColor={'bg-sky-50'}/>
</div>
</div>

{/* TESTIMONIALS */}
<div className="flex flex-col items-center content-center m-4">
  <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
  </img>
 </div>
<div className="flex flex-col items-center md:flex mb-4">
    <h2 className="text-center text-blue-700 font-bold text-sm font-RobottoPara"> TESTIMONIALS</h2>
    <h1 className="text-center font-bold font-Outfit text-md mb-2 text-xl md:text-3xl">What Our Patient Say About Us</h1>
    <div className="flex flex-col items-center space-y-8 mt-4
    md:flex-row md:space-x-8 md:justify-center md:space-y-0 md:mt-8">
        <Card imagaUrl={'./assets/male.svg'} name={'Dr.Damodharan Varadarajan'} position={'Google '} content={review1} index={0}/>
        <Card imagaUrl={'./assets/femaleAvatar.svg'} name={'Mrs.Rekha'} position={'Just Dial'} content={review4} index={2}/>
        <Card imagaUrl={'./assets/male.svg'} name={'Mr.Haris Haris'} position={'Google'} content={review3} index={1}/>
        <Card imagaUrl={'./assets/male.svg'} name={'Mr.Ram Kumar'} position={'Just Dial'} content={review2} index={2}/>
    </div>
</div>
</div>
    );
}


export default Home;