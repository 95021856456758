import { React } from "react";
import { Helmet } from "react-helmet";
import './index.css';


const Contact = () => {
    return (
        <div className='mt-[6.4rem] md:mt-20 bg-cover bg-right' style={{ backgroundImage: 'url(./assets/89125.jpg)'}}>
            <Helmet>
                <title>Contact Us - best ayurveda doctors in Chennai</title>
                <meta property="og:title" content="Contact Us - Kottakkal Arya Vaidya Sala Pammal - Best Ayurveda doctors contact details"></meta>
                <meta property="og:image" content="/kottakkalaryavaidyasalapammal-logo.ico"></meta>
            </Helmet>
            <div className='flex flex-col items-center justify-center'>
            <div className="flex flex-col items-center content-center mt-8">
                <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
                </img>
            </div>
                <h1 className=' text-violet-800 font-bold text-4xl font-header pb-8 mt-6'>Let's Connect With Us</h1>
                <h2 className=' text-gray-500 md:text-xl font-header md:text-center text-base m-4 md:m-0'>Any Questions? We are happy to help you.<br /> Send us a message and we will be touch within one working day.</h2>
            </div>
            <div className="flex md:space-x-12 md:mt-6 items-center justify-center flex-col md:flex-row">
                <h2 className='self-center pr-2 flex md:order-last md:-mt-2 text-gray-500 text-xl font-header'> <span><a href='tel:9445581844'><img src='./assets/phone.svg' className=' h-6 pr-2 text-gray-500 text-xl font-header' alt='kottakkal arya vaidya sala pammal phone icon'></img></a></span> 94455 81844</h2>
                <h2 className='self-center pr-2 flex md:order-last md:-mt-2 text-gray-500 text-xl font-header'> <span><a href='tel:9445581844'><img src='./assets/kottakkalaryavaidyasalapammal-mai.svg' className=' h-6 pr-2 text-gray-500 text-xl font-header font-bold' alt='kottakkal arya vaidya sala pammal phone icon'></img></a></span> kottakkalpammaldealer@gmail.com</h2>
                <h2 className='self-center pr-2 flex md:order-last md:-mt-2 text-gray-500 text-xl font-header'> <span><a href='https://wa.me/9445581844?text=Hi' target="_blank"><img src='./assets/kottakkalaryavaidyasalapammal-whatsapp.svg' className=' h-6 pr-2 text-gray-500 text-xl font-header' alt='kottakkal arya vaidya sala pammal phone icon'></img></a></span> 94455 81844</h2>
            </div>
            <div className="flex flex-col items-center content-center mt-8">
                <img src="./assets/kottakkalaryavaidyasalapammal-separator.svg" className="h-7 w-7 content-center" >
                </img>
            </div>
            <h1 className="text-violet-700 font-bold text-2xl font-header pb-8 mt-6 text-center">Our Clinic Location</h1>
            <div className="flex flex-col items-center justify-center">              
                    <iframe className="w-full h-96" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9155303414623!2d80.13314197479383!3d12.977254314754868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fd10cb31ab9%3A0xfb8591f797b5d0ce!2sKottakkal%20Arya%20Vaidya%20Sala%20Pammal!5e0!3m2!1sen!2sin!4v1709126584076!5m2!1sen!2sin" width="auto" height="auto"  loading="lazy"></iframe>
            </div>

        </div>
    );
}

export default Contact;