import React from 'react'
import Review from './kottakkalaryavaidyasalapammal-review';
const Card = ({name, position, imagaUrl, content, index}) => {
  const imageMargin = index > 0 ? 'md:-mt-28' : 'md:-mt-16';
  return (
    <div className='flex flex-col relative items-center justify-center bg-green-50 border-green-300 rounded-xl w-60 md:h-56'>
  <img src={imagaUrl} alt='Dr.Nirose profile' className={`h-12 w-12 -mt-4 rounded-full ${imageMargin} `}></img>
  <h1 className='text-green-700 font-semibold'>{name}</h1>
  <h2 className='text-sm text-gray-500 font-semibold'>{position}</h2>
  <Review />
  <p className='text-center pt-4 text-sm text-gray-600 font-RobottoPara mb-2'>{content}</p>
</div>

  )
}

export default Card;