import React  from "react"; 
import TreatmentCard from "./kottakkalaryavaidyasalapammal-card-treatments";
import { Helmet } from "react-helmet";

const Treatment = ()=>{
  const dharaContent = 'Discover the healing benefits of Dhara Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Dhara, an Ayurvedic therapy, entails a continuous flow of warm herbal oil, milk, or buttermilk over the forehead or the whole body. This treatment induces deep relaxation, relieves stress, and promotes mental clarity. Dhara also contributes to balancing the nervous system, enhancing sleep quality, and supporting overall physical and emotional well-being.'
  const abhyangaContent = 'Discover the healing benefits of Abhyanga Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Abhyangam, an Ayurvedic therapy, involves a full-body massage with warm herbal oil, providing numerous health benefits. This treatment nourishes the skin, enhances blood circulation, and promotes relaxation. Abhyanga helps alleviate muscle tension, reduces stress, and supports overall well-being by balancing the body\'s energies'
  const nasyamContent = 'Discover the healing benefits of Nasyam Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Nasyam Panchakarma therapy offers relief from sinus issues, headaches, and respiratory disorders by cleansing and rejuvenating the nasal passages. It aids in improving mental clarity and alleviates stress, promoting overall well-being. This Ayurvedic treatment contributes to a strengthened immune system and enhanced respiratory health.'
  const navarakizhiContent = 'Discover the healing benefits of Navara Kizhi Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Navara Kizhi, an Ayurvedic therapy, rejuvenates the body and promotes muscle strength through the application of a warm herbal poultice. This treatment aids in alleviating joint pain, arthritis, and muscle stiffness. Navara Kizhi enhances blood circulation, nourishes tissues, and contributes to overall physical vitality and flexibility.'
  const tarpanamContent = 'Discover the healing benefits of Tarpanam Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Tarpanam, an Ayurvedic therapy, provides nourishment to the eyes and promotes ocular health through a soothing process involving medicated ghee. This treatment is beneficial for relieving dry eyes, improving vision, and addressing eye fatigue. Tarpanam also contributes to preventing eye disorders and promoting a sense of relaxation and clarity.'
  const geevavastiContent = 'Discover the healing benefits of Greeva Vasti Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Greeva Vasti, an Ayurvedic therapy, focuses on neck and upper back health by using a warm herbal oil reservoir. This treatment effectively alleviates neck pain, stiffness, and muscular tension. Greeva Vasti improves flexibility, reduces inflammation, and provides relief from conditions like cervical spondylosis'
  const udwartanamContent = 'Discover the healing benefits of Udwartanam Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Udwartanam, an Ayurvedic therapy, involves a therapeutic dry powder massage that stimulates blood circulation and exfoliates the skin. This treatment aids in reducing excess body fat, cellulite, and imparts a revitalized and toned appearance to the skin. Udwartanam promotes lymphatic drainage, improves skin texture, and leaves the body feeling invigorated.'
  const raktamokshanamleechtherapyContent = 'Discover the healing benefits of Raksha Mokshanam(Leech Therapy) Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Raksha Mokshanam, an Ayurvedic therapy, is a bloodletting procedure that purifies the blood and removes toxins from the body. This treatment aids in alleviating skin disorders, promoting detoxification, and enhancing overall skin health. Raksha Mokshanam supports the body\'s natural healing processes and contributes to a balanced and revitalized state.'
  const januvastiContent = 'Discover the healing benefits of Janu Vasti Panchakarma therapy at Kottakkal Arya Vaidya Sala in Pammal. Janu Vasti, an Ayurvedic therapy, focuses on knee health by applying medicated oil to the knee joints. This treatment effectively relieves knee pain, stiffness, and inflammation caused by conditions like osteoarthritis. Janu Vasti improves joint mobility, strengthens muscles, and promotes overall knee function and flexibility.'
    return(
  <div>
      <Helmet>
      <title>Treatments - Best Panchakarma treament centers in Chennai</title>
                <meta property="og:title" content="Treatment - Panchakarma treament centers in Chennai"></meta>
                <meta property="og:image" content="/kottakkalaryavaidyasalapammal-logo.ico"></meta>
      </Helmet>
    <div className="mt-[6.4rem] md:mt-20 bg-cover" style={ {background:'url(./assets/avs-pattern.png)'}}>
    <h2 className="text-center text-blue-700 font-bold text-sm font-RobottoPara pt-2"> TREATMENTS</h2>
 <h1 className="text-center font-bold text-3xl pb-4">Ayurchakra offering Treatments</h1>
    <div className="md:flex md:flex-col md:items-center">
    <TreatmentCard title={'DHARA'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-shiroda.jpg'} whyToUse={'Why to suggest Dhara'} content={dharaContent}/>
    <br/>
    <TreatmentCard title={'ABHYANGAM'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-abhyanga.jpg'} whyToUse={'Why to suggest Abhyangam'} content={abhyangaContent}/>
    <br/>
    <TreatmentCard title={'NASYAM'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-nasyam.jpg'} whyToUse={'Why to suggest Nasyam'} content={nasyamContent}/>
    <br/>
    <TreatmentCard title={'NAVARA KIZHI'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-navarakizhi.jpg'} whyToUse={'Why to suggest Navara Kizhi'} content={navarakizhiContent}/>
    <br/>
    <TreatmentCard title={'TARPANAM'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-tharpanam.jpg'} whyToUse={'Why to suggest Tarpanam'} content={tarpanamContent}/>
    <br/>
    <TreatmentCard title={'GREEVA VASTI'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-greevabasthi.jpg'} whyToUse={'Why to suggest Greeva Vasti'} content={geevavastiContent}/>
    <br/>
    <TreatmentCard title={'UDWARTANAM'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-udwarthanam.jpg'} whyToUse={'Why to suggest Udwartanam'} content={udwartanamContent}/>
    <br/>
    <TreatmentCard title={'RAKSHA MOKSHANAM'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-leechtherapy.jpeg'} whyToUse={'Why to suggest Raksha Mokshanam'} content={raktamokshanamleechtherapyContent}/>
    <br/>
    <TreatmentCard title={'JANU VASTI'} imagaUrl={'./assets/treatments/kottakkalaryavaidyasalapammal-janubasti.webp'} whyToUse={'Why to suggest Janu Vasti'} content={januvastiContent}/>
    <br/>
    </div>
    
    </div>
  </div>
    );
}

export default Treatment;