import React from 'react'
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  return (
    <div className='mt-[6.4rem] md:mt-20'>
      <Helmet>
      <title>About - Kottakkal Arya Vaidya Sala Pammal | arya vaidya sala dealer in Chennai</title>
                <meta property="og:title" content="About Us- Kottakkal Arya Vaidya Sala Pammal"></meta>
                <meta property="og:image" content="/kottakkalaryavaidyasalapammal-logo.ico"></meta>
      </Helmet>
      <div className='flex md:flex-row flex-col'>
        <div className='flex flex-col md:space-x-20 ml-4 mr-4 md:w-1/2'>
          <h1 className=' text-violet-700 font-bold md:text-5xl font-header md:p-4 md:ml-14 md:mt-6 text-3xl p-2 mt-4'>Who We Are?</h1>`
          <h2 className=' text-violet-700 font-bold text-2xl font-header md:ml-12 ml-2'>Best Ayurveda practitioners in Chennai</h2>`
          <p className='text-md text-gray-500 text-justify md:pr-32 pr-4 font-header'>
            Welcome to Kottakkal Arya Vaidya Sala Pammal, where we are proud to be your trusted destination for authentic Kottakkal Ayurveda medicines. With over 8 years of experience, As the foremost dealer in Pammal, our commitment is to provide you with a comprehensive selection of high-quality Ayurvedic products sourced directly from the renowned Kottakkal Arya Vaidya Sala. At Kottakkal Arya Vaidya Sala Pammal, authenticity is at the heart of our offerings. Our medicines are procured directly from Kottakkal Arya Vaidya Sala, ensuring that you receive genuine and effective Ayurvedic treatments.
            Our team, comprised of experienced Ayurvedic practitioners, is dedicated to offering personalized guidance on the right medicines and treatments for your specific health needs. <br /><br />Explore our diverse range of Kottakkal Ayurveda medicines, including classical formulations, herbal remedies, and wellness products. We prioritize quality assurance in every product we offer – from sourcing to storage and delivery. Our vision is to be recognized as the best dealer of Kottakkal Ayurveda medicines, providing unparalleled service and promoting the timeless wisdom of Ayurveda for holistic well-being. Visit Kottakkal Arya Vaidya Sala Pammal in Pammal to experience the healing power of Ayurveda. Consult with our Ayurvedic experts for personalized health guidance and explore our range of authentic Ayurvedic products. Thank you for choosing Kottakkal Arya Vaidya Sala Pammal – your trusted partner in Ayurvedic wellness. For inquiries and orders, contact us at 94455 81844.        </p>
        </div>
        <div className='md:flex flex-col hidden'>
          <img src='./assets/kottakkalaryavaidyasalapammal-home.jpg' className='w-full  h-1/2 rounded'>
          </img>
          <div className='flex-wrap mt-4 gap-8 hidden md:flex'>
            <div className=" w-[46%]  bg-violet-600 rounded-xl">
              <p className="font-bold text-2xl text-white p-2 md:text-4xl">300+</p>
              <p className="font-bold text-sm text-white p-2 md:text-xl">Happy Customers</p>
            </div>
            <div className="w-[46%] bg-violet-600 rounded-xl">
              <p className="font-bold text-2xl text-white p-2 md:text-4xl">07+</p>
              <p className="font-bold text-sm text-white p-2 md:text-xl">Years of Experience</p>
            </div>
            <div className="w-[46%] bg-violet-600 rounded-xl">
              <p className="font-bold text-2xl text-white p-2 md:text-4xl">04+</p>
              <p className="font-bold text-sm text-white p-2 md:text-xl">Experienced Doctors</p>
            </div>
            <div className="w-[46%] bg-violet-600 rounded-xl">
              <p className="font-bold text-2xl text-white p-2 md:text-4xl">03+</p>
              <p className="font-bold text-sm text-white p-2 md:text-xl">Experienced Therapists</p>
            </div>
          </div>
        </div>


      </div>

      <div>

      </div>
      {/* <h2 className='text-center font-bold text-xl mt-8 font-RobottoPara text-blue-700'> MEET OUR TEAM</h2>
      <div className='flex flex-col items-center space-y-6 md:flex md:flex-row md:space-x-8 md:justify-between md:ml-16 md:mr-8'>
        <AboutCard name={'Dr. Nivethitha Kumar'} position={'Director'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-nivethithakumar.jpg'} />
        <AboutCard name={'Dr. Priya Balaji'} position={'Doctor'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-priyabalaji.jpg'} />
        <AboutCard name={'Er. Palanivel Mani'} position={'Technical Analyst'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-palanivelmani.jpg'} />
        <AboutCard name={'Mrs. Ranjini Rajendran'} position={'Pharmacist'} imagaUrl={'./assets/about/kottakkalaryavaidyasalapammal-about-pharmacist.png'} />
      </div> */}
    </div>
  )
}

export default AboutUs; 