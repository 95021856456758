import React from 'react'

const DoctorCard = ({ name, position, imagaUrl, content, bgColor, bgStyle }) => {
  return (
    <div className={`flex border-green-300 rounded-3xl md:ml-6 md:mr-6 ${bgColor} ${bgStyle}  h-[144px]`}>
      <img src={imagaUrl} alt='Dr.Nirose profile' className={`h-36 w-32`}></img>
      <div className='flex-col ml-4 mr-4'>
        <p className='text-sm text-gray-700 font-Outfit md:pb-2 md:pt-2  md:text-base'>{content}</p>
        <div className='flex flex-col justify-end grow md:mt-6'>
          <h1 className='text-sky-700 font-bold font-header'>{name}</h1>
          <h2 className='text-sm text-green-700 font-header font-semibold text'>{position}</h2>
        </div>
      </div>
    </div>

  )
}

export default DoctorCard;