import React from 'react'

const ServiceCard = ({name, imagaUrl, content, bgColor, notRounded}) => {
  const showRounded = notRounded ? '' : 'rounded-full';
  const showImageBackground = notRounded ? '' : 'bg-blue-100 rounded-full';
  return (
    <div className={`flex flex-col mt-4 relative items-center ${bgColor} border-green-300 rounded-xl h-[296px] w-60 flex-nowrap `}>
        <div className={`${showImageBackground} py-2 px-3 mt-4`}>
        <img src={imagaUrl} alt='Dr.Nirose profile' className={`h-12 w-12 ${showRounded}`}></img>
        </div>
        <h1 className='  text-emerald-500  text- font-semibold text-md'>{name}</h1>
        <br/>
        <p className=' text-center p-3 text-sm text-gray-600 font-RobottoPara' >{content}</p>
    </div>
  )
}

export default ServiceCard; 
